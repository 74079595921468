import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import { GrLocation } from "react-icons/gr";
import { removeCityFromFullName } from "utils/helpers/_general";

interface City {
  id: number;
  name: string;
  country_name: string;
  full_name?: string;
}

interface CitiesAutocompleteListProps {
  cities: City[];
  setLocationInputState: (state: string) => void;
  setLocationQuery: (id: number, name: string, type: string) => void;
  setShowLocations: (state: boolean) => void;
  setNoAutoComplete: (state: boolean) => void;
}

const CitiesAutocompleteList: React.FC<CitiesAutocompleteListProps> = ({
  cities,
  setLocationInputState,
  setLocationQuery,
  setShowLocations,
  setNoAutoComplete,
}) => {
  const { t: translate } = useTranslation();

  const handleCityClick = (city: City) => {
    const cityName = parse(
      city.name.replace(/<em>/g, "").replace(/<\/em>/g, "") +
        ", " +
        city.country_name
    ).toString();

    setLocationInputState(cityName);
    setLocationQuery(city.id, cityName, "city");
    setShowLocations(false);
    setNoAutoComplete(true);
  };

  return (
    <>
      <h3 className="mb-3 text-sm text-slate-400">
        {/* {translate("searchBar.Cities")} */}
      </h3>
      <ul>
        {cities.map((city) => (
          <li
            key={city.id}
            className="flex gap-2 p-2 text-xs border-b border-solid rounded-none cursor-pointer hover:bg-gray-100 text-slate-600"
            onClick={() => handleCityClick(city)}
            role="button"
            aria-label={`Select city ${city.name}`}
          >
            <div className="flex-shrink-0">
              <GrLocation className="opacity-50" size={20} />
            </div>
            <div>
              <h1 className="not-italic">
                {parse(city.name.replace(/<em>/g, "").replace(/<\/em>/g, ""))}
                {!city.full_name && `, ${city.country_name}`}
              </h1>
              <h4
                className="mt-1 text-xs opacity-50"
                style={{ fontFamily: "Roboto" }}
              >
                {city.name && city.full_name
                  ? removeCityFromFullName(city.name, city.full_name)
                  : ""}
              </h4>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CitiesAutocompleteList;
